import React from 'react'
import { useSelector } from 'react-redux'

import { Image } from '../../components'
import { LoginPanel } from '../../containers'
import {
  getGlobalSettingLoadingStatus,
  getLoginPageMainUrl,
  getPortalLogoUrl
} from '../../redux/slices/global'
import {
  LoginGridContainer,
  LoginGridItem,
  LoginContainer,
  LoginImageContainer,
  LoginContentContainer,
  LoginExtraText
} from './StyledLoginItems'

const LoginPage = () => {
  const appLogo = useSelector(getPortalLogoUrl)
  const loginImageUrl = useSelector(getLoginPageMainUrl)
  const globalLoading = useSelector(getGlobalSettingLoadingStatus)

  return (
    <LoginGridContainer>
      <LoginGridItem>
        <LoginContainer>
          <LoginImageContainer>
            <Image
              width='100%'
              height='100%'
              src={appLogo}
              alt='Login'
              objectFit='contain'
              loading={globalLoading}
            />
          </LoginImageContainer>
          <LoginContentContainer>
            <LoginPanel />
          </LoginContentContainer>
          <LoginExtraText>
            If you are a new user or experiencing issues logging into
            the portal, please contact Access Mercantile Support Team&nbsp;
            <a href='mailto: idebt360support@accessmercantile.com.au'>idebt360support@accessmercantile.com.au</a>
          </LoginExtraText>
        </LoginContainer>
      </LoginGridItem>
      <LoginGridItem className='mobile-hidden'>
        <Image
          width='100%'
          height='100%'
          src={loginImageUrl}
          alt='Side'
          objectFit='cover'
          loading={globalLoading}
        />
      </LoginGridItem>
    </LoginGridContainer>
  )
}

export default LoginPage
